import React from "react"
import RichTextRenderer from "../../elements/RichTextRenderer"
import { GatsbyImage } from "gatsby-plugin-image"
import "./style.scss"

interface IStepperProps {
  cards?: Array<any>
}

const Stepper: React.FC<IStepperProps> = ({ cards }) => {
  const classes = ["bg-primary", "bg-tertiary"]
  return cards ? (
    <div className="steps">
      <div className="container-stepper">
        {cards.map((card, index) => (
          <div className="row step-item fade-in-up" key={index}>
            <div className={"col-lg-11"}>
              <div className={`row${index % 2 === 0 ? " flex-row-reverse" : ""}`}>
                <div className={`col-lg-5${index % 2 === 0 ? " " : " col-xxl-6 "}p-0`}>
                  <GatsbyImage
                    image={card.image.fluid}
                    alt={card.image.title || ""}
                    className="img-fluid"
                  />
                </div>
                <div
                  className={`step-content col-lg-7 ${index % 2 === 0 ? " " : " col-xxl-6 "}${
                    classes[index % classes.length]
                  }`}
                >
                  <p className="step-count">{index + 1}</p>
                  <h2 className="h3">{card.title}</h2>
                  <RichTextRenderer json={card.content} noContainer />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null
}

export default Stepper
