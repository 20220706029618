import React from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import CentralImage from "../components/central-image"
import HeroMazeDefault from "../components/hero-default"
import Widget from "../components/widgets"
import CTA from "../elements/CTA"
import FaqListing from "../components/faq-listing"
import Stepper from "../components/stepper"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"
import { GatsbyImage } from "gatsby-plugin-image"

export interface IHowWeHire {
  title: string
  seoTitle: string
  seoDescription: string
  heroHeading: string
  heroContent: {
    heroContent: string
  }

  centralImage: {
    title: string
    fluid: any
  }

  faqHeading?: string
  faqDescription?: {
    faqDescription: string
  }
  faqButton: any

  contentCards?: Array<any>
  widgets?: Array<any>
}

interface IHowWeHireProps {
  contentfulCareersHowWeHire: IHowWeHire
  allContentfulCareersFaqItem: {
    nodes: Array<any>
  }
  allContentfulComponentCard: {
    edges: Array<Object>
  }
}

interface ICareersHowWeHireContext {
  id: string
  slug: string
  locale: string
}

const InsideGuidewire: React.FC<PageProps<IHowWeHireProps, ICareersHowWeHireContext>> = ({
  pageContext,
  path,
  data,
}) => {
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: data.contentfulCareersHowWeHire.seoTitle || data.contentfulCareersHowWeHire.title,
    description: data.contentfulCareersHowWeHire.seoDescription,
    image: "",
  }

  const normalizeData = edges => {
    const updated: Object[] = []
    for (let i = 0; i < edges.length; i++) {
      updated.push(edges[i].node)
    }
    return updated
  }
  // not sure why cards were fetched from allContentfulCards(where they lose reference order and are fetched in random order) instead from contentCards(where order is correct), therefore its commented out. If needed uncomment it and remove the new const below.
  // const contentCardsUpdated = normalizeData(data.allContentfulComponentCard.edges)
  const contentCardsUpdated = data.contentfulCareersHowWeHire.contentCards

  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substring(0, 2),
      }}
    >
      {/* Layout contains menus */}
      <Layout seoData={seoData} dispatch={() => false}>
        <HeroMazeDefault
          heading={data.contentfulCareersHowWeHire.heroHeading}
          content={data.contentfulCareersHowWeHire.heroContent}
          className='how-we-hire-hero'
        />
        <div className="full-width-image">
          <GatsbyImage
            image={{ ...data.contentfulCareersHowWeHire.centralImage.fluid, aspectRatio: 21 / 9 }}
            alt={data.contentfulCareersHowWeHire.centralImage.title || ""}
          />
        </div>

        <Stepper cards={contentCardsUpdated} />

        <div className="faqs-simple">
          <div className="container ">
            <div className="row justify-content-between">
              <div className="col-lg-5 col-xl-4">
                {data.contentfulCareersHowWeHire.faqHeading && <h2>{data.contentfulCareersHowWeHire.faqHeading}</h2>}
                {data.contentfulCareersHowWeHire.faqDescription && (
                  <p>{data.contentfulCareersHowWeHire.faqDescription.faqDescription}</p>
                )}
                <CTA {...data.contentfulCareersHowWeHire.faqButton} />
              </div>
              <div className="col-lg-7 col-xl-6">
                <FaqListing faqItems={data.allContentfulCareersFaqItem.nodes} />
              </div>
            </div>
          </div>
        </div>
        {data.contentfulCareersHowWeHire.widgets &&
          data.contentfulCareersHowWeHire.widgets.map((widgetData, i) => <Widget {...widgetData} key={i} />)}
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

export default InsideGuidewire

export const query = graphql`
  query($id: String!) {
    contentfulCareersHowWeHire(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      heroHeading
      heroContent {
        __typename
        ...RichTextField
      }

      centralImage {
        fluid: gatsbyImageData(width: 2048 aspectRatio: 2.1 quality: 100 formats: [AUTO, WEBP])        
        title
      }

      contentCards {
        ...ComponentCard
      }

      faqHeading
      faqDescription {
        faqDescription
      }
      faqButton {
        ...ContentfulLink
      }

      widgets {
        ...ComponentCTA
      }
    }
    allContentfulCareersFaqItem(limit: 5) {
      nodes {
        title
        category
        content {
          ...RichTextField
        }
      }
    }
    allContentfulComponentCard(filter: { careers___how_we_hire: { elemMatch: { id: { eq: $id } } } }) {
      edges {
        node {
          title
          tagline
          variation
          heading
          subheading
          content {
            raw
          }
          image {
            title
            url
          }
        }
      }
    }
  }
`
